<template>
  <q-page class="bg-white no-wrap page-container">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <q-card square class="bg-white">
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_travelling') }}
        </q-card-section>
        <q-card-section>
          <q-select
            id="type-select"
            v-model="selectedType"
            outlined
            type="select"
            :options="types"
            placeholder="Choose type"
            autocomplete="off"
            label="Type"
          />
        </q-card-section>
        <single-return v-if="selectedType.value === 'single-return'" />
        <!-- <multi-journey v-if="selectedType.value === 'multi-journey'" /> -->
      </q-card>
    </div>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import store from 'store'
import SingleReturn from './single-return.vue'

export default {
  components: { SingleReturn },
  data () {
    return {
      types: [
        {
          label: 'Single/Return',
          value: 'single-return'
        }
        // Temporarily disabling these options
        // TODO: To re-enable once tickets for these journey types are scoped out
        // {
        //   label: 'Multi-Journey',
        //   value: 'multi-journey'
        // }
        // {
        //   label: 'Explorer Pass',
        //   value: 'explorer-pass'
        // },
        // {
        //   label: 'Redeem Explorer Pass',
        //   value: 'redeem-explorer-pass'
        // }
      ],
      selectedType: {
        label: 'Single/Return',
        value: 'single-return'
      }
    }
  },
  beforeMount () {
    store.dispatch('ondemand/stash', { results: null, selected: null, params: null, luggageParams: null })
  }
}
</script>

<style lang="stylus" scoped>
.page-container
  @media (max-width 768px)
    padding 1rem
.mobile
  .code
    display none

.q-page
  display flex
  width 100%
  flex-direction column
  align-items center
  background white

</style>
