<template>
  <transition name="dropdown">
    <div v-if="showList" class="list">
      <q-scroll-area class="list-inner">
        <q-list id="stop-list" no-border>
          <q-item
            v-for="stop in stops"
            :key="stop.stop_code"
            clickable
            data-testid="stop"
            @click="() => $emit('selected', stop)"
          >
            <q-item-section>
              {{ stop.description }}
            </q-item-section>
          </q-item>
          <q-btn
            color="primary"
            class="close"
            :label="$t('close')"
            @click="() => $emit('close')"
          />
        </q-list>
      </q-scroll-area>
    </div>
  </transition>
</template>

<script>
import 'styles/ondemand.styl'

export default {
  props: ['stops', 'showList'],
  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>

.list
  position: absolute;
  background: white;
  z-index: 100;
  width: 100%
  height: 42vh
  max-width 420px
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

.list-inner
  height: 100%

#stop-list
  height auto

.close
  width 100%
  position sticky

</style>
