<template>
  <q-card-section class="card-section">
    <div class="form">
      <div class="stop-select">
        <q-input
          id="from-select"
          v-model="stopSearchOrigin"
          outlined
          placeholder="From"
          autocomplete="off"
          debounce="500"
          @focus="() => showOriginStopList = true"
        >
          <template v-slot:prepend>
            <q-icon name="place" color="primary" />
          </template>
        </q-input>
        <!-- Departure Selection list -->
        <stop-list
          :stops="originStops"
          :show-list="showOriginStopList && originStops.length"
          @close="closeOriginStops"
          @selected="setOriginStop"
        />
      </div>
      <button id="swap-button" aria-label="swap" @click="reverseRoute">
        <q-icon size="24px" name="fa fa-sort-alt" color="primary" />
      </button>
      <div class="stop-select">
        <q-input
          id="to-select"
          v-model="stopSearchDestination"
          outlined
          placeholder="To"
          autocomplete="off"
          debounce="500"
          @focus="() => showDestinationStopList = true"
        >
          <template v-slot:prepend>
            <q-icon name="place" color="primary" />
          </template>
        </q-input>
        <!-- Destination Selection list -->
        <stop-list
          :stops="destinationStops"
          :show-list="showDestinationStopList && destinationStops.length"
          @close="closeDestinationStops"
          @selected="setDestinationStop"
        />
      </div>
      <div class="oneway-return">
        <q-radio v-model="returning" :val="false" label="One way" />
        <q-radio v-model="returning" :val="true" label="Return" />
      </div>
      <!-- Depart date-->
      <depart-return
        aria-label="Departure Time"
        custom-label="Departure Time"
        :value="`${params.departure_date} ${params.departure_time}`"
        class="time-transport"
        :date-options="dateOptions"
        :time-options="() => true"
        :show-return="true"
        @change="setDepartDate"
      />
      <!-- Return date -->
      <depart-return
        v-if="returning"
        :value="`${params.return_date} ${params.return_time}`"
        class="time-transport"
        :date-options="returnDateOptions"
        :time-options="returnTimeOptions"
        :show-return="true"
        @change="setReturnDate"
      />
    </div>
    <!-- Passengers button -->
    <button
      class="time-transport-return"
      @click="togglePassengerForm"
    >
      <q-icon name="fas fa-user" color="primary" class="left-icon" />
      <div class="button-label">
        {{ $tc('passenger', 2) }} ({{ totalPassengers }})
      </div>
      <div class="button-value">
        {{ passengerDisplay }}
      </div>
      <q-icon name="fas fa-angle-right" color="primary" class="right-icon" />
    </button>

    <!-- Passengers form -->
    <m-dropdown-transition :collapsed="!showPassengerForm">
      <passenger-form
        v-if="showPassengerForm"
        :passengers="passengers"
        @update="val => passengers = val"
      />
    </m-dropdown-transition>
    <div class="form">
      <label>Request Wheelchair Space</label>
      <span class="wheelchair-info">Each coach has one wheelchair space, dimensions must not exceed 1200mm x 700mm x 13500mm.
        <br>Please select the passenger who requires this space.</span>
      <div class="wheelchair-selection">
        <q-radio v-model="params.wheelchairSpaceRequired" val="NONE" label="Wheelchair not required" />
        <q-radio v-for="option in wheelchairOptions" :key="option.fareId" v-model="params.wheelchairSpaceRequired" :val="option.fareId" :label="option.label" />
      </div>
      <span class="luggage-info">Each passenger is entitled to store one piece of luggage in the hold. Do you require additional storage space?<br>Please note that there may be an additional cost for this.</span>
      <div class="wheelchair-selection">
        <q-radio v-model="params.holdLuggageRequired" :val="true" label="Yes" />
        <q-radio v-model="params.holdLuggageRequired" :val="false" label="No" />
      </div>
    </div>
    <!-- Submit -->
    <m-cta-button
      :label="params.holdLuggageRequired ? 'Add Hold Items' : 'Find Tickets'"
      :disabled="$v.$invalid"
      @ctaClick="submit"
    />
  </q-card-section>
</template>

<script>
import 'styles/ondemand.styl'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import departReturn from 'components/MLeaveNow/depart-return.vue'
import passengerForm from 'components/MLeaveNow/passenger-form.vue'
import { MDropdownTransition, MCtaButton } from 'components/'
import stopList from './stop-list.vue'
import date from 'utils/date-time'
import { stops as getStops } from 'api/coach'
const { newDate, toCivilTime, toCivilDate, addToDate } = date

// Validator function to ensure at least 1 passenger type is selected
const minOnePassengerOfType = (passengerTypes) => {
  return value => {
    if (typeof value !== 'object') return false

    return passengerTypes.some(type => value[type] >= 1)
  }
}

export default {
  components: { departReturn, stopList, passengerForm, MDropdownTransition, MCtaButton },
  data () {
    return {
      returning: false,
      showOriginStopList: false,
      stopSearchOrigin: '',
      showDestinationStopList: false,
      stopSearchDestination: '',
      originStops: [],
      destinationStops: [],
      showPassengerForm: false,
      params: {
        departure_date: toCivilDate(newDate()),
        departure_time: toCivilTime(newDate()),
        return_date: toCivilDate(newDate()),
        return_time: toCivilTime(addToDate(newDate(), { minutes: 15 })),
        origin: {
          id: null,
          description: null
        },
        destination: {
          id: null,
          description: null
        },
        passengers: {
          adults: 1,
          children: 0,
          sixtyPlusNecHolders: 0,
          underTwentyTwoNecHolders: 0,
          volunteerNecHolders: 0
        },
        wheelchairSpaceRequired: 'NONE',
        holdLuggageRequired: false
      }
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    passengers: {
      get () {
        const { adults, children, sixtyPlusNecHolders, underTwentyTwoNecHolders, volunteerNecHolders } = this.params.passengers
        return { adults, children, sixtyPlusNecHolders, underTwentyTwoNecHolders, volunteerNecHolders }
      },
      set (val) {
        const { adults, children, sixtyPlusNecHolders, underTwentyTwoNecHolders, volunteerNecHolders } = val
        this.params.passengers.adults = adults
        this.params.passengers.children = children
        this.params.passengers.sixtyPlusNecHolders = sixtyPlusNecHolders
        this.params.passengers.underTwentyTwoNecHolders = underTwentyTwoNecHolders
        this.params.passengers.volunteerNecHolders = volunteerNecHolders
      }
    },
    passengerDisplay () {
      const { adults, children, sixtyPlusNecHolders, underTwentyTwoNecHolders, volunteerNecHolders } = this.params.passengers
      let passengerList = []
      if (adults) passengerList.push(this.$tc('num.adults', adults))
      if (children) passengerList.push(this.$tc('num.children', children))
      if (sixtyPlusNecHolders) passengerList.push(this.$tc('num.sixtyPlusNecHolders', sixtyPlusNecHolders))
      if (underTwentyTwoNecHolders) passengerList.push(this.$tc('num.underTwentyTwoNecHolders', underTwentyTwoNecHolders))
      if (volunteerNecHolders) passengerList.push(this.$tc('num.volunteerNecHolders', volunteerNecHolders))
      return passengerList.join(', ')
    },
    totalPassengers () {
      let total = 0
      for (const key in this.params.passengers) {
        total += this.params.passengers[key]
      }
      return total
    },
    wheelchairOptions () {
      const selectedPassengerTypes = Object.keys(this.params.passengers).filter(passengerType => this.params.passengers[passengerType] > 0)
      return selectedPassengerTypes.map(type => {
        if (type === 'adults') return { label: 'Adult', fareId: 'ADULT' }
        if (type === 'children') return { label: 'Child', fareId: 'CHILD' }
        if (type === 'sixtyPlusNecHolders') return { label: '60+/Disabled NEC Holder', fareId: 'CONCESSION' }
        if (type === 'underTwentyTwoNecHolders') return { label: 'Under 22 NEC Holder', fareId: 'U22' }
        if (type === 'volunteerNecHolders') return { label: 'Volunteer NEC Holder', fareId: 'YPEC' }
        return type
      })
    }

  },
  validations: {
    params: {
      passengers: {
        minOnePassenger: minOnePassengerOfType(['adults', 'children', 'sixtyPlusNecHolders', 'underTwentyTwoNecHolders', 'volunteerNecHolders'])
      },
      origin: {
        id: { required }
      },
      destination:
      { id: { required } }
    }
  },
  watch: {
    async stopSearchOrigin (value) {
      if (value.length) {
        const stops = await getStops(value)
        this.originStops = stops.filter(stop => stop.stop_code !== this.params.destination.id)
      } else {
        this.originStops = []
        this.params.origin_id = null
      }
    },
    async stopSearchDestination (value) {
      if (value.length) {
        const stops = await getStops(value)
        this.destinationStops = stops.filter(stop => stop.stop_code !== this.params.origin.id)
      } else {
        this.destinationStops = []
        this.params.destination_id = null
      }
    },
    'params.passengers': {
      // Ensure wheelchair selection is still valid after passenger type update/removal
      handler () {
        const validWheelchairSelection = this.wheelchairOptions.includes(this.params.wheelchairSpaceRequired) || this.params.wheelchairSpaceRequired === 'NONE'
        if (!validWheelchairSelection) this.params.wheelchairSpaceRequired = 'NONE'
      },
      deep: true
    }
  },
  created () {
    if (this.stash.formParams) {
      const { stopSearchOrigin, stopSearchDestination, returning, ...rest } = this.stash.formParams
      this.params = rest
      this.stopSearchOrigin = stopSearchOrigin
      this.stopSearchDestination = stopSearchDestination
      this.returning = returning
    }
  },
  methods: {
    closeOriginStops () {
      this.showOriginStopList = false
      this.stopSearchOrigin = this.params.origin.description
    },
    closeDestinationStops () {
      this.showDestinationStopList = false
      this.stopSearchDestination = this.params.destination.description
    },

    setOriginStop (stop) {
      this.params.origin.id = stop.stop_code
      this.params.origin.description = stop.description
      this.stopSearchOrigin = stop.description
      this.showOriginStopList = false
    },
    setDestinationStop (stop) {
      this.params.destination.id = stop.stop_code
      this.params.destination.description = stop.description
      this.stopSearchDestination = stop.description
      this.showDestinationStopList = false
    },
    reverseRoute () {
      const departStop = this.stopSearchOrigin
      const departStopId = this.params.origin_id

      const returnStop = this.stopSearchDestination
      const returnStopId = this.params.destination_id

      this.stopSearchOrigin = returnStop
      this.params.origin_id = returnStopId

      this.stopSearchDestination = departStop
      this.params.destination_id = departStopId
    },
    dateOptions (date) {
      return date >= toCivilDate(newDate()).replace(/-/g, '/') && date <= toCivilDate(addToDate(newDate(), { years: 2 })).replace(/-/g, '/')
    },
    returnDateOptions (date) {
      return date >= this.params.departure_date.replace(/-/g, '/') && date <= toCivilDate(addToDate(this.params.departure_date, { years: 2 })).replace(/-/g, '/')
    },
    returnTimeOptions (hour, minutes) {
      // If return date is another day, allow all times
      if (this.params.return_date > this.params.departure_date) return true
      // If same day, only allow 15 minutes after departure time (can be adjusted)
      const [departureHour, departureMinutes] = this.params.departure_time.split(':')
      if (hour < departureHour) return false
      if (minutes === null) return true
      if (hour === Number(departureHour)) {
        if (minutes < (Number(departureMinutes) + 15)) return false
      }
      return true
    },
    setDepartDate (val) {
      const [date, time] = val.split(' ')
      this.params.departure_date = date
      this.params.departure_time = time
      // Ensure return parameters are updated
      if (this.params.return_date < date) {
        this.params.return_date = date
      }
      if (this.params.return_date === date && this.params.return_time <= time) {
        this.params.return_time = toCivilTime(addToDate(time, { minutes: 15 }))
      }
    },
    setReturnDate (val) {
      const [date, time] = val.split(' ')
      this.params.return_date = date
      this.params.return_time = time
    },
    togglePassengerForm (event) {
      event.preventDefault()
      this.showPassengerForm = !this.showPassengerForm
    },
    submit () {
      const { origin, destination, departure_date, departure_time } = this.params
      let parameters = {
        journey_type_id: this.returning ? 'return' : 'single',
        origin_id: origin.id,
        destination_id: destination.id,
        departure_date,
        departure_time: `${departure_time}:00`
      }

      const fareClassMap = {
        adults: 'ADULT',
        children: 'CHILD',
        sixtyPlusNecHolders: 'CONCESSION',
        underTwentyTwoNecHolders: 'U22',
        volunteerNecHolders: 'YPEC'
      }

      let fare_ids = []

      for (let passengerType in this.params.passengers) {
        const count = this.params.passengers[passengerType]
        if (count > 0) {
          fare_ids.push({
            fare_id: fareClassMap[passengerType],
            count
          })
        }
      }

      parameters = { ...parameters,
        fare_ids
      }

      if (this.returning) {
        parameters = {
          ...parameters,
          return_date: this.params.return_date,
          return_time: `${this.params.return_time}:00`
        }
      }

      if (this.params.wheelchairSpaceRequired !== 'NONE') {
        parameters = {
          ...parameters,
          ssr: [
            {
              fare_line_id: this.params.wheelchairSpaceRequired,
              ssr_type: 'WHEELCHAIR',
              ssr_count: 1
            }
          ]
        }
      }

      // params for api call, formParams for pre-popluating form
      this.$store.dispatch('ondemand/stash', { params: parameters,
        formParams: {
          ...this.params,
          returning: this.returning,
          stopSearchOrigin: this.stopSearchOrigin,
          stopSearchDestination: this.stopSearchDestination
        }
      })

      if (this.params.holdLuggageRequired) {
        this.$router.push({ name: 'ondemand-coach-luggage' })
      } else {
        this.$router.push({ name: 'ondemand-coach-outbound' })
      }
    } }
}
</script>

<style lang="stylus" scoped>

.card-section
  @media (max-width 768px)
    padding 1rem

label
  font-weight: 500
  grid-column: 1 / span 3
.form
  width 100%
  display grid
  grid-template-columns 1fr 36px 1fr
  align-items center
  gap: 1rem
  padding: 0.5rem 0
  @media (max-width 768px)
    display flex
    flex-direction column

.stop-select
  position relative
  @media (max-width 768px)
    width 100%

#swap-button
  background white
  border none

.oneway-return
  grid-column: 1 / span 3
  display: flex
  gap: 2rem
  justify-content: center

.time-transport
  background-color white
  border none
  width 100%
  display grid
  grid-template-columns 20px auto auto 20px
  grid-column-gap 5px
  align-items center
  padding 10px 5px
  grid-column: 1 / span 3

.return
  grid-column: 3

.button-label, .left-icon
  justify-self start

.button-value, .right-icon
  justify-self end

.time-transport-return
  background-color white
  border none
  width 100%
  display grid
  grid-template-columns 20px auto auto 20px
  grid-column-gap 5px
  align-items center
  padding 10px 5px
  grid-column: 1 / span 3

.wheelchair-selection
  display: grid
  grid-template-columns: repeat(2, auto)
  justify-content: center
  gap: 1rem
  grid-column: 1 / span 3

.wheelchair-info
  grid-column: 1 / span 3
.luggage-info
  grid-column: 1 / span 3
</style>
